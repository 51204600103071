import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Blog from 'routes/blog'

export default (props) => (
  <StaticQuery
    query={graphql`
      {
        allSanityBlogPost(sort: { fields: date, order: DESC }) {
          edges {
            node {
              blogImages {
                asset {
                  url
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 320
                        height: 240
                        layout: CONSTRAINED
                      )
                    }
                  }
                }
              }
              id
              title
              category {
                title
              }
              description
              slug {
                current
              }
              readTime
            }
          }
        }
      }
    `}
    render={({ allSanityBlogPost: { edges } }) => (
      <Blog edges={edges} {...props} />
    )}
  />
)
