import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rem } from 'polished'
import queryString from 'query-string'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import {
  COLOR,
  FONT_SIZE,
  FONT_WEIGHT,
  MAX_CONTENT_WIDTH,
  SPACE,
  mq,
} from 'Theme'
import GenericPage from 'components/GenericPage'
import SEO from 'components/SEO'
import { Box } from 'components/Layout'
import { Text } from 'components/Typography'

const Article = styled(Link)`
  display: flex;
  align-items: center;

  text-decoration: none;

  color: ${COLOR.WHITE};

  ${mq.to.M`
    align-items: flex-start;
    flex-direction: column;
  `}

  & + & {
    margin-top: ${SPACE.L};

    ${mq.to.M`
      margin-top: ${SPACE.XL};
    `}
  }
`

const Image = styled(GatsbyImage)`
  flex: none;

  ${mq.from.M`
    height: ${rem(240)};
    width: ${rem(288)};
    margin-right: ${SPACE.XL};
  `}

  ${mq.to.M`
    width: 100%;
    margin-bottom: ${SPACE.M};
  `}
`

const getCategoriesByEdges = (edges) => {
  const kurzy = edges.filter(
    ({
      node: {
        category: { title },
      },
    }) => title === 'kurzy'
  )
  return { kurzy }
}

const CATEGORIES = ['kurzy', 'všetko']

const getCategoryFromSearch = (search) => {
  const { query } = queryString.parseUrl(search)
  return query.category && CATEGORIES.includes(query.category)
    ? query.category
    : 'všetko'
}

const Blog = ({ edges, location: { search } }) => {
  const [categories, setCategories] = React.useState({ kurzy: [], všetko: [] })
  const selectedCategory = getCategoryFromSearch(search)

  React.useEffect(() => {
    setCategories({
      ...getCategoriesByEdges(edges),
      všetko: edges,
    })
  }, [edges])

  return (
    <GenericPage>
      <SEO
        title="Blog"
        description="Nauč sa u nás nové zručnosti a zabezpeč si tak prácu u niektorého z našich lokálnych IT partnerov!"
        meta={[
          {
            name: 'title',
            content:
              'Sudo Academy | IT kurzy | JavaScript | Produktový manažment',
          },
        ]}
      />
      <Box
        maxWidth={MAX_CONTENT_WIDTH.BLOG}
        mx="auto"
        pt={rem(150)}
        pb={SPACE.XL}
      >
        <Text
          as="h1"
          fontFamily="Telegraf"
          fontSize={FONT_SIZE.XXXXL}
          fontWeight={FONT_WEIGHT.NORMAL}
          mb={SPACE.XL}
        >
          Najnovšie články
        </Text>
        {categories[selectedCategory].map(
          ({
            node: {
              id,
              title,
              blogImages,
              description,
              slug: { current },
              readTime,
            },
          }) => {
            const {
              asset: {
                localFile: { childImageSharp },
              },
            } = blogImages[0] ?? {}

            return (
              <Article to={`/blog/${current}`} key={id}>
                <Image image={childImageSharp.gatsbyImageData} />
                <div>
                  <Text
                    as="h2"
                    fontSize={FONT_SIZE.XXL}
                    fontWeight={FONT_WEIGHT.MEDIUM}
                    lineHeight={1.3}
                  >
                    {title}
                  </Text>
                  <Text as="p" fontSize={FONT_SIZE.L} fontStyle="italic">
                    {description}
                  </Text>
                  <Text
                    as="p"
                    fontSize={FONT_SIZE.L}
                    fontStyle="italic"
                    paddingTop="8px"
                  >
                    {readTime} min read
                  </Text>
                </div>
              </Article>
            )
          }
        )}
      </Box>
    </GenericPage>
  )
}

Blog.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  edges: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        blogImages: PropTypes.arrayOf(
          PropTypes.shape({
            asset: PropTypes.shape({
              url: PropTypes.string.isRequired,
              localFile: PropTypes.shape({
                childImageSharp: PropTypes.shape({
                  fluid: PropTypes.object.isRequired,
                }).isRequired,
              }).isRequired,
            }).isRequired,
          }).isRequired
        ).isRequired,
        category: PropTypes.shape({
          title: PropTypes.string.isRequired,
        }).isRequired,
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        slug: PropTypes.shape({
          current: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default Blog
